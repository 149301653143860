<template>
  <div v-if="showLoadingAnimation" class="lds-ripple">
    <div></div>
    <div></div>
  </div>
  <br />
  <div class="msg" :style="{ color: color }">{{ message }}</div>
  <br />
  <div class="msg">{{ anotherMessage }}</div>
  
  <Button @btn-click="cancel" text="Unregister game" :class="{ invisible: !cancelVisibility, visible: cancelVisibility }"/>
</template>

<script>
import Button from "./Button";

export default {
  name: "Wait",
  props: {
    message: String,
    anotherMessage: String,
    showLoadingAnimation: Boolean,
    color: String,
    cancelVisibility: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    }
  },
  components: {
    Button
  }
};
</script>

<style lang="css" scoped>
.msg {
  font-size: 20px;
}

.lds-ripple {
  margin: 2em;
  margin-top: 1px;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #78ffd6;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
  margin-top: 50px;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>