<template>
  <div class="form-control">
    <label>Register a game</label>
    <input class="bet" type="text" v-model="bet" name="bet" placeholder="Bet (e.g. 0.1)" />
    <select v-model="unit" class="select" name="unit">
      <option value="wei" selected>wei</option>
      <option value="gwei">gwei</option>
      <option value="finney">finney</option>
      <option value="ether">ether</option>
    </select>
    <br />
    <Button @btn-click="register" text="Register"/>
  </div>

  <h1>OR</h1>
  <div class="form-control">
    <label>Join a game</label>
    <input
      class="address"
      type="text"
      v-model="address"
      name="address"
      placeholder="Address Opponent: 0x..."
    />
    <br />
    <Button @btn-click="join" text="Join"/>
  </div>
</template>

<script>
import Button from './Button'

export default {
  name: "start",
  emits: {
    register: null,
    join: null
  },
  components: {
    Button
  },
  data() {
    return {
      bet: "",
      unit: "",
      address: "",
    };
  },
  methods: {
    register() {
      // check for errors
      if (!this.bet) {
        alert("Please enter a bet");
        return;
      }
      if (this.bet.includes(",")) {
        alert("Please use a dot (.) instead of a comma (,)");
        return;
      }
      if (!this.unit) {
        alert("Please choose an unit");
        return;
      }
      this.$emit("register", this.bet, this.unit);
    },
    join() {
        //check for error
        if(!this.address) {
            alert('Please enter an address')
            return;
        }
        this.$emit("join", this.address)
    }
  },
};
</script>


<style scoped>
.add-form {
  margin-bottom: 40px;
}
.form-control {
  margin: 20px 0;
}
.form-control label {
  display: block;
}
.select {
  width: 5;
  height: 38px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 15px;
}
.bet {
  width: 25%;
  height: 30px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 15px;
  margin-bottom: 1em;
}
.address {
  width: 70%;
  height: 30px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 15px;
  margin-bottom: 1em;
}
</style>