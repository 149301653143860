<template>
  <div class="msg" :style="{ color: commitStatusColor }">
    {{ commitStatus }}
  </div>

  <Button @btn-click="reveal" text="Reveal" />

  <br>
  <br>
  <br>

  <div class="msg" :style="{ color: revealStatusColor }">
    {{ revealStatus }}
  </div>

  <div class="msg1"> Your choice: {{yourchoice}} </div>
  <div class="msg1"> Opponent choice: {{opponentchoice}}</div>

  <br>
  <br>

  <Button @btn-click="checkWinner" text="Check Winner" />

  <div class="hint"> <i>Opponent not answering anymore? After 5 minutes you can check the winner and claim the win! </i></div>

  <Button
    @btn-click="withdraw"
    text="Withdraw"
    :class="{ invisible: !withdrawVisibility, visible: withdrawVisibility }"
  />
</template>

<script>
import Button from "./Button";

export default {
  name: "Reveal",
  props: {
    commitStatus: String,
    commitStatusColor: String,
    revealStatus: String,
    revealStatusColor: String,
    withdrawVisibility: Boolean,
    yourchoice: String,
    opponentchoice: String,
  },

  components: {
    Button,
  },
  methods: {
    reveal() {
      this.$emit("reveal");
    },
    checkWinner() {
      this.$emit("check-winner");
    },
    withdraw() {
      this.$emit("withdraw-player");
    }
  },
};
</script>

<style scoped>
.msg {
  font-size: 20px;
  margin: 20px 20px;
}

.msg1 {
  font-size: 18px;
  
}

.hint {
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}
</style>