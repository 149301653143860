<template>
  <img :ondblclick=admin src="../assets/tim.jpg" alt="" />
  <h4>Creator: Tim Käbisch</h4>
  <h4>E-Mail: tkaebisch@pm.me</h4>
  <h4>LinkedIn: <a href="https://www.linkedin.com/in/timkaebisch/" target="_blank" style="color:#FFFFFF;">linkedin.com/in/timkaebisch/</a></h4>
  <h2>Version 1.0.0</h2>
  <label :onclick=goback> <u>Go back</u></label>
</template>

<script>
export default {
  name: "About",
  methods: {
    goback() {
      this.$emit('close-about');
    },
    admin() {
      this.$emit('show-admin');
    }
  }
}
</script>

<style scoped>
img {
  border-radius: 50%;
  border: 5px #333 solid;
  margin-bottom: 1rem;
  width: 30%;
  margin-bottom: 2em;
}

label {
  cursor: pointer;
}
</style>