<template>
  <header>
    <h1>ROCK PAPER SCISSORS</h1>
    <h3>on Ethereum</h3>
    <p>Current Address: {{ address }}</p>
  </header>
</template>

<script>
export default {
  name: "Header",
  props: {
    address: String,
  },
};
</script>

<style scoped>
h3 {
  margin-top: -1em;
}
p {
  font-family: monospace;
}
header {
  margin-bottom: 4em;
}
</style>
