<template>
    <button @click="onClick()" >{{ text }}</button>
</template>

<script>
export default {
    name: 'Button',
    props: {
        text: String
    },
    methods: {
        onClick() {
            this.$emit('btn-click')
        }
    }
}
</script>

<style scoped>
button {
  padding: 10px 25px;
  -webkit-box-flex: 0;
  flex: 0 auto;
  border: 2px solid #78ffd6;
  background-color: transparent;
  transition-property: none;
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 8px;
  font-family: urw-din, sans-serif;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  color: #fff;
  line-height: inherit;
  text-decoration: none;
}
</style>
