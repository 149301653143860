<template>
  <footer>
    <p>Copyright &copy; 2021</p>
  </footer>
</template>


<style scoped>
a {
  color: #333;
}

.tx {
  font-size: 14px;
}

footer {
  margin-top: 40px;
  text-align: center;
}
</style>