<template>
  <h3>Make your choice:</h3>
  <div>
    <img @click="onClick('rock')" :class="{ 'marked': choice == 'rock', 'unmarked': choice != 'rock' }" src="../assets/rock.png" alt=""  />
    <img @click="onClick('paper')" :class="{ 'marked': choice == 'paper', 'unmarked': choice != 'paper' }" src="../assets/paper.png" alt="" />
    <img @click="onClick('scissors')" :class="{ 'marked': choice == 'scissors', 'unmarked': choice != 'scissors' }" src="../assets/scissors.png" alt="" />

    <div>

    <input
      class="password"
      type="text"
      v-model="password"
      name="password"
      placeholder="Password (please choose a strong one)"
    />
    <br>
    <div class="hint">Your choice and password is saved by your browser - you don't have to note it.</div>
    <Button @btn-click="commit" text="Commit"/>


    </div>
  </div>
</template>

<script>
import Button from './Button'

export default {
  name: "Commit",
  data() {
      return {
          choice: "",
          password: ""
      }
  },
  methods: {
      onClick(c) {
          this.choice = c;
      },
      commit() {
          if(!this.choice) {
              alert('Please make a choice!')
          } else if(!this.password) {
              alert('Please choose a password!')
          } else {
              this.$emit('commit', this.choice, this.password)
          }
      }
  },
  components: {
      Button
  }
};
</script>

<style scoped>
img {
  border-radius: 50%;
  border: 6px #333 solid;
  margin: 10px;
  width: 20%;
  margin-bottom: 2em;
  cursor: pointer;
}

.marked {
    border-color: red;
}

.unmarked {
    border-color: #333;
}
.password {
  width: 68%;
  height: 30px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 15px;
  margin-bottom: 2em;
}

.hint {
  font-size: 10.8px;
  margin-top: -26px;
  margin-bottom: 35px;
}


</style>