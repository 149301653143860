<template>
    <h1> Administrator Area </h1>
    <div>
        <input class="textfield" type="text" v-model="balance" name="balance" placeholder="Balance in wei" readonly />
        <Button @btn-click="getBalance" text="Balance"/>

        <input class="textfield" type="text" v-model="withdraw" name="withdraw" placeholder="Amount in wei" />
        <Button @btn-click="withdrawAdmin" text="Withdraw"/>

        
    </div>
    <br>
    <label :onclick=goback> <u>Home</u></label>
</template>

<script>
import Button from './Button'

export default {
    name: "Admin",
    data() {
        return {
            withdraw: null,
        }
    },
    props: {
        balance: String
    },
    components: {
        Button,
    },
    methods: {
        getBalance() {
            this.$emit('get-balance')
        },
        withdrawAdmin() {
            if(!this.withdraw) {
                alert('Please insert an amount!')
                return
            }
            this.$emit('withdraw-admin', this.withdraw)
        },
        goback() {
            this.$emit('close-admin')
        }
    }

}
</script>

<style scoped>
.textfield {
  width: 40%;
  height: 33px;
  margin: 10px;
  padding: 1px 7px;
  font-size: 18px;
  margin-bottom: 1em;
}

label {
  cursor: pointer;
}
</style>