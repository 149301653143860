<template>
  <div>
    <button class="button" @click="$emit('start-game')"><span>start game!</span></button>
  </div>
  <label :onclick=about><u> <span>About</span></u></label>
</template>

<script>
export default {
  name: "Home",
  emits: ["start-game", "about"],
  methods: {
    about() {
      this.$emit('about');
    }
  }
};
</script>

<style scoped>
.button {
  margin-bottom: 60px;
  background-image: linear-gradient(0deg, #007991, #78ffd6);
  min-width: 20px;
  padding: 15px 30px;
  border-radius: 8px;
  font-weight: 700;
  letter-spacing: 2px;
  margin-top: 0;
  background-color: #007991;
  font-family: urw-din, sans-serif;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  color: #fff;
  border: 0;
  line-height: inherit;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 20px;
  width: 250px;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.3s;
}

.button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}

label {
  cursor: pointer;
}
</style>

