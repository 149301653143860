<template>
  <!-- Hier Logo einfügen
  <img alt="Vue logo" src="./assets/RPS.png"> -->
  <div class="container">
    <Header :address="currentAddress" />

    <!-- -->
    <home v-if="showHome" @start-game="start" @about="about"></home>
    <start v-if="showStart" @register="register" @join="join"></start>
    <about
      v-if="showAbout"
      @close-about="closeAbout"
      @show-admin="admin"
    ></about>
    <wait
      v-if="showWait"
      @cancel="cancel"
      v-bind:message="status"
      v-bind:showLoadingAnimation="statusLoading"
      v-bind:anotherMessage="anotherStatus"
      v-bind:color="statuscolor"
      v-bind:cancelVisibility="cancelVisibility"
    ></wait>

    <admin
      v-if="showAdmin"
      @get-balance="getBalance"
      @withdraw-admin="withdrawAdmin"
      @close-admin="closeAdmin"
      v-bind:balance="factoryContractBalance"
    ></admin>
    <commit v-if="showCommit" @commit="commit"></commit>

    <reveal
      v-if="showReveal"
      @reveal="reveal"
      @check-winner="checkWinner"
      @withdraw-player="withdrawPlayer"
      v-bind:commitStatus="commitStatus"
      v-bind:commitStatusColor="commitStatusColor"
      v-bind:revealStatus="revealStatus"
      v-bind:revealStatusColor="revealStatusColor"
      v-bind:yourchoice="choice"
      v-bind:opponentchoice="opponentchoice"
      v-bind:withdrawVisibility="withdrawVisibility"
    >
    </reveal>

    <Footer />
  </div>
</template>

<script>
//const factoryContractAddress = "0x0Cbe4c66417CD6c1fCB6eFF862b8fEE3e0154075";
//const factoryContractAddress = "0x07554dEBD6AB4716d15F09D3f2314D04F3714E29"; // Ganache
const factoryContractAddress = "0xd93cFA473c4860BFCe70FcAE6e8f6f4C2b569Be5" // Lukso L14

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import About from "./components/About";
import Start from "./components/Start";
import Wait from "./components/Wait";
import Admin from "./components/Admin";
import Commit from "./components/Commit";
import Reveal from "./components/Reveal";
import { ethers } from "ethers";

// ABIs
const gameABI = require("./contracts/Game.json");
const factoryABI = require("./contracts/Factory.json");

// globals
let signer;
let factoryContract;
let gameContract;
let provider;

export default {
  name: "App",
  components: {
    Header,
    Footer,
    Home,
    About,
    Start,
    Wait,
    Commit,
    Reveal,
    Admin,
  },
  data() {
    return {
      showHome: true,
      showAbout: false,
      showStart: false,
      showWait: false,
      showAdmin: false,
      showCommit: false,
      showReveal: false,

      currentAddress: "0x",
      bet: "",
      factoryContractBalance: "",
      player: "", // registering a game = player 1
      // joining a game = player 2

      // Commit
      choice: "",
      password: "",

      // Reveal
      commitStatus:
        "Opponent has not yet committed - you should not yet reveal!",
      commitStatusColor: "red",
      revealStatus: "Opponent has not yet revealed!",
      revealStatusColor: "red",
      opponentchoice: "..",
      withdrawVisibility: false,

      // Wait
      status: "",
      statuscolor: "",
      statusLoading: true,
      anotherStatus: "",
      cancelVisibility: false,
    };
  },
  async beforeMount() {
    if (window.ethereum.isMetaMask) {
      const res = await ethereum.request({ method: "eth_requestAccounts" });
      this.currentAddress = res[0];

      provider = new ethers.providers.Web3Provider(window.ethereum);
      signer = provider.getSigner();
      factoryContract = new ethers.Contract(
        factoryContractAddress,
        factoryABI,
        signer
      );
      //if ((await provider.getNetwork()).name != "ropsten") {
      //  alert("This App works on Ropsten only! Please change the network and reaload the page!");
      //}
    } else {
      alert("This App requires MetaMask. (https://metamask.io/)");
    }

    // event handlers
    window.ethereum.on("accountsChanged", (accounts) => {
      this.currentAddress = accounts[0];
    });

    factoryContract.on("GameCreated", (player1, player2, bet, game) => {
      let adr = this.currentAddress.toLowerCase();
      // check if the current address is part of the triggered "GameCreated" event
      if (adr === player1.toLowerCase() || adr === player2.toLowerCase()) {
        console.log("Game Contract: ", game);
        this.bet = bet.toString();
        gameContract = new ethers.Contract(game, gameABI, signer);
        var self = this;
        setTimeout(function () {
          self.showWait = false;
          self.cancelVisibility = false;
          self.showCommit = true;
        }, 4000);
      }

      // event handler for game contract
      gameContract.on("CommitmentsReceived", () => {
        this.commitStatus =
          "Both players commited - you can reveal your choice!";
        this.commitStatusColor = "green";
      });

      gameContract.on("ChoicesReceived", (player1Choice, player2Choice) => {
        this.revealStatus =
          "Both players revealed - winner can be checked now!";
        this.revealStatusColor = "green";

        if (this.player == 1) {
          this.opponentchoice = player2Choice;
        } else if (this.player == 2) {
          this.opponentchoice = player1Choice;
        } else {
          alert("Unknown error!");
        }
      });

      gameContract.on("WinnerDetermined", (winner) => {
        if (
          winner.toLowerCase() === this.currentAddress ||
          winner === "0x0000000000000000000000000000000000000000"
        ) {
          // the current user won or its a draw --> enable withdraw button
          this.withdrawVisibility = true;
          this.showWait = false;
          this.showReveal = true;
        } else {
          // the current user lost --> reload page for a new game
          alert("You lost - Thanks for playing and better luck next time! :-)");
          location.reload();
        }
      });

      // check winner was called, but winner could not be determined yet
      gameContract.on("WinnerMissing", () => {
        alert(
          "Winner could not yet be determined! (Opponent has not yet reveald but stil time to do so!)"
        );
        this.showWait = false;
        this.showReveal = true;
      });
    });
  },
  methods: {
    /* SCREEN ADJUSTMENT METHODS*/

    // start game - show start screen
    async start() {
      if (this.currentAddress === "0x") {
        alert("Please reload the page and log into MetaMask to start playing!");
      } else {
        //if ((await provider.getNetwork()).name != "ropsten") {
        //  alert("This App works on Ropsten only! Please change the network and reaload the page!");
        //}
        //else {
        this.showHome = false;
         this.showStart = true;
        //}
      }
    },

    // show about
    about() {
      this.showHome = false;
      this.showAbout = true;
    },

    // close about, show home
    closeAbout() {
      this.showAbout = false;
      this.showHome = true;
    },

    // show admin
    admin() {
      this.showAbout = false;
      this.showAdmin = true;
    },

    // close admin, show home
    closeAdmin() {
      this.showAdmin = false;
      this.showHome = true;
    },

    /* SMART CONTRACT INTERACTION */

    register(bet, uint) {
      this.player = 1;

      let _bet = ethers.utils.parseUnits(bet, uint);

      let overrides = {
        from: this.currentAddress,
        value: ethers.utils.parseUnits("500", "wei"),
      };

      var promise;
      promise = factoryContract.registerGame(_bet, overrides);

      promise.catch(function (error) {
        alert("Transaction denied or user already registered a game!");
        return;
      });

      var self = this;
      promise.then(function (result) {
        self.showStart = false;
        self.statusLoading = true;
        self.statuscolor = "red";
        self.status = "Waiting for confirmation..";
        self.showWait = true;

        var _promise = result.wait(1);
        _promise.then(function (_result) {
          // block confirmed
          console.log(
            "https://ropsten.etherscan.io/tx/" + _result.transactionHash
          );
          self.statusLoading = false;
          self.statuscolor = "green";
          self.status = "Transaction confirmed!";
          self.anotherStatus = "Waiting for an opponent..";
          self.cancelVisibility = true;
        });
      });
    },

    cancel() {
      var promise;
      promise = factoryContract.unregisterGame();

      promise.catch(function (error) {
        alert("Transaction denied or no game registered!");
        return;
      });

      var self = this;
      promise.then(function (result) {
        self.cancelVisibility = false;
        self.statusLoading = true;
        self.statuscolor = "red";
        self.status = "Waiting for confirmation..";
        self.anotherStatus = "";

        var _promise = result.wait(1);
        _promise.then(function (_result) {
          // block confirmed
          console.log(
            "https://ropsten.etherscan.io/tx/" + _result.transactionHash
          );
          self.statusLoading = false;
          self.statuscolor = "green";
          self.status = "Transaction confirmed!";
          setTimeout(function () {
            self.showWait = false;
            self.showStart = true;
          }, 4000);
        });
      });
    },

    join(address) {
      this.player = 2;

      let overrides = {
        //gasLimit: 3000000,
        from: this.currentAddress,
        value: ethers.utils.parseUnits("500", "wei"),
      };

      var promise;
      promise = factoryContract.joinGame(address, overrides);

      promise.catch(function (error) {
        alert(
          "Transaction denied, game not existing or registered a game yourself!"
        );
        return;
      });

      var self = this;
      promise.then(function (result) {
        self.showStart = false;
        self.statusLoading = true;
        self.statuscolor = "red";
        self.status = "Waiting for confirmation..";
        self.showWait = true;

        var _promise = result.wait(1);
        _promise.then(function (_result) {
          // block confirmed
          console.log(
            "https://ropsten.etherscan.io/tx/" + _result.transactionHash
          );
          self.statusLoading = false;
          self.statuscolor = "green";
          self.status = "Transaction confirmed!";
        });
      });
    },

    commit(choice, password) {
      // save choice and password for reveal
      this.choice = choice;
      this.password = password;

      // "bet" needs to be the value of the tx
      let overrides = {
        from: this.currentAddress,
        value: ethers.utils.parseUnits(this.bet, "wei"),
      };

      // create sha256 of choice and password concatenated
      const hash = ethers.utils.soliditySha256(["string"], [choice + password]);

      var promise;
      promise = gameContract.submitHash(hash, overrides);

      promise.catch(function (error) {
        alert("Transaction denied!");
        return;
      });

      var self = this;
      promise.then(function (result) {
        self.showCommit = false;
        self.statusLoading = true;
        self.statuscolor = "red";
        self.status = "Waiting for confirmation..";
        self.anotherStatus = "";
        self.showWait = true;

        var _promise = result.wait(1);
        _promise.then(function (_result) {
          // block confirmed
          console.log(
            "https://ropsten.etherscan.io/tx/" + _result.transactionHash
          );
          self.statusLoading = false;
          self.statuscolor = "green";
          self.status = "Transaction confirmed!";
          setTimeout(function () {
            self.showWait = false;
            self.showReveal = true;
          }, 4000);
        });
      });
    },

    reveal() {
      var promise;
      promise = gameContract.reveal(this.choice, this.password);

      promise.catch(function (error) {
        alert("Transaction denied!");
        return;
      });

      var self = this;
      promise.then(function (result) {
        self.showReveal = false;
        self.statusLoading = true;
        self.statuscolor = "red";
        self.status = "Waiting for confirmation..";
        self.anotherStatus = "";
        self.showWait = true;

        var _promise = result.wait(1);
        _promise.then(function (_result) {
          // block confirmed
          console.log(
            "https://ropsten.etherscan.io/tx/" + _result.transactionHash
          );
          self.statusLoading = false;
          self.statuscolor = "green";
          self.status = "Transaction confirmed!";
          setTimeout(function () {
            self.showWait = false;
            self.showReveal = true;
          }, 4000);
        });
      });
    },

    checkWinner() {
      var promise;
      promise = gameContract.checkWinner();

      promise.catch(function (error) {
        alert("Transaction denied or winner was already checked!");
        return;
      });

      var self = this;
      promise.then(function (result) {
        self.showReveal = false;
        self.statusLoading = true;
        self.statuscolor = "red";
        self.status = "Waiting for confirmation..";
        self.anotherStatus = "";
        self.showWait = true;

        var _promise = result.wait(1);
        _promise.then(function (_result) {
          // block confirmed
          console.log(
            "https://ropsten.etherscan.io/tx/" + _result.transactionHash
          );
          self.statusLoading = false;
          self.statuscolor = "green";
          self.status = "Transaction confirmed!";
          // Event winnerDetermined and winnerMissing will open the reveal site again
        });
      });
    },

    withdrawPlayer() {
      var promise;
      promise = gameContract.withdraw();

      promise.catch(function (error) {
        alert("Transaction denied!");
        return;
      });

      var self = this;
      promise.then(function (result) {
        self.showReveal = false;
        self.statusLoading = true;
        self.statuscolor = "red";
        self.status = "Waiting for confirmation..";
        self.anotherStatus = "";
        self.showWait = true;

        var _promise = result.wait(1);
        _promise.then(function (_result) {
          // block confirmed
          console.log(
            "https://ropsten.etherscan.io/tx/" + _result.transactionHash
          );
          self.statusLoading = false;
          self.statuscolor = "green";
          self.status = "Transaction confirmed!";
          self.anotherStatus =
            "Thanks for playing! See you in the next game! :-)";
          setTimeout(function () {
            alert("Start new Game..");
            location.reload(); // reload page (i.e. start a new game)
          }, 7000);
        });
      });

      // diese Funktion
      // die beiden winner events
    },

    async getBalance() {
      var callPromise = factoryContract.getBalance();

      callPromise.catch(function (error) {
        alert("Balance can only be retrieved by contract owner!");
      });

      var self = this;
      callPromise.then(function (result) {
        self.factoryContractBalance = result.toString();
      });
    },

    withdrawAdmin(amount) {
      var promise;
      promise = factoryContract.withdrawFees(amount);

      promise.catch(function (error) {
        alert(
          "Transaction denied, you are not the contract owner or amount too high!"
        );
        return;
      });

      var self = this;
      promise.then(function (result) {
        self.showAdmin = false;
        self.statusLoading = true;
        self.statuscolor = "red";
        self.status = "Waiting for confirmation..";
        self.showWait = true;

        var _promise = result.wait(1);
        _promise.then(function (_result) {
          // block confirmed
          console.log(
            "https://ropsten.etherscan.io/tx/" + _result.transactionHash
          );
          self.statusLoading = false;
          self.statuscolor = "green";
          self.status = "Transaction confirmed!";
          self.anotherStatus = "";
          setTimeout(function () {
            self.showWait = false;
            self.showAdmin = true;
            self.factoryContractBalance = null;
          }, 4000);
        });
      });
    },
  },
};
</script>

<style>
@font-face {
  font-family: "DejaVu Sans";
  src: url("assets/webfonts/DejaVuSans.ttf");
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #d8dee9;
  background-color: #1a1d23;
  font-family: "DejaVu Sans";
}

.container {
  max-width: 600px;
  margin: 30px auto;
  overflow: auto;
  min-height: 300px;
  border: 2px solid #78ffd6;
  padding: 30px;
  border-radius: 5px;
}

.info {
  text-align: left;
  font-size: 0.6em;
}
h4 {
  margin-top: -1em;
}
html {
  background-color: #1a1d23;
}
</style>